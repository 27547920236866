/* .app__sub__titles {
    color: blueviolet;
}


*/
.app__title {
    font-size: xx-large;
}
.res__summary {
   max-width: 400px;
   min-width: none;
} 

.whole__damn__page {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    border: 330px; */
    border: 4px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* background-color: rgb(62, 62, 62); */
    max-width: 500px;
    min-width: 500px;
    /* justify-content: right; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #0f360c;/* Change this to your desired button background color */
    color: #fff; /* Change this to your desired button text color */
    text-decoration: none;
    border: none;
    border-radius: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    justify-content: space-between;
}

/* Hover effect */
.button:hover {
    background-color: #0c4c07; /* Change this to your desired hover color */
}
