img {
  height: 340px;  
}

.projects {
    border: 40px;
    justify-content: space-between;
    flex-wrap: wrap;
    /* background-color: rgb(17, 30, 21); */
    padding: .01%;
    max-width: 1200px;

    
}

.app__link {
    justify-content: right;
    opacity: 80%;
    margin: 20px;
}

h2 {
    /* background-color: rgb(85, 84, 84); */
    opacity: 70%;
}