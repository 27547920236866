/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
.boody {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #010718;
}
.container {
    position: relative;
    color: pink;
    font-size: 30px;
    font-weight: 600;
    overflow: hidden;
    background-color: black;
}
.container .text.first-text{
    color: #FFF;
}
.text.sec-text:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-left: 2px solid;
    animation: animate 4s steps(12) infinite;
    background-color: black;
}
@keyframes animate{
    40%, 60%{
        left: 100%;
    }
    100%{
        left: 0%
;    }
    
} */
/* h3 {
    font-family: cursive;
    background-color: rgb(15, 17, 18);
    opacity: 50%;
    max-width: 100px;
}

h1 {
    color: aliceblue;
} */
/* 

body {
    background-color: #120439;
    height: 100%;
    font-family: 'tradegothiclt-bold', sans-serif;
  }
  
  h1 {
    font-size: 5em;
    color: white;
    text-transform: uppercase;
  }
  
  span {
    border-right: .05em solid;
    animation: caret 1s steps(1) infinite;
  }
  
  @keyframes caret {
    50% {
      border-color: transparent;
    }
  } */

.about__sec {
    max-width: 500px;
    /* background-color: rgb(29, 36, 29); */
    /* opacity: 30%; */
    /* color:antiquewhite; */
    font-size: large;
    border-color: antiquewhite;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 5px;
    background-color: #38373764;
    max-width: 600px;
    /* left: 40%; */
}

.button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #0f360c;/* Change this to your desired button background color */
    color: #fff; /* Change this to your desired button text color */
    text-decoration: none;
    border: none;
    border-radius: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    justify-content: space-between;
}

/* Hover effect */
.button:hover {
    background-color: #0c4c07; /* Change this to your desired hover color */
}
