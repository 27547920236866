div {
    color: antiquewhite;
}

.home__logo {
    display: flex;
    justify-content: center;
    opacity: 50%;
    max-height: 800px;
    min-height: 400px;
    max-width: 800px;
    min-width: none;
    /* width: 80%; */
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);

}