/* .tech__stack__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .stacky__title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .tech__stack {
    width: 90px; 
    height: 100px; 
    margin: 10px;
  }
   */

   .tech__stack {
    opacity: 10000%;
    min-height: 140px;
    max-height: 140px;
    min-width: 140px;
    max-width: 140px;
    border: 10px;
    border-color: blanchedalmond;
    justify-content: space-between;
}
.tech__stack 

.skill__titles {
    /* color: rgb(223, 39, 233); */
    max-height: 30px;
}

.tech__stack__container {
    border: 4px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* background-color: rgb(62, 62, 62); */
    max-width: 500px;
    min-width: 500px;
    /* justify-content: right; */
    position: absolute;
    top: 80%;
    left: 60%;
    transform: translate(-50%, -50%);


}
.grow img{
    transition: 1s ease;
    }
    
    .grow img:hover{
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 1s ease;
    }
    
.stacky__title {
    /* color: rgb(179, 179, 226); */
    font-weight: bold;
    font-size: 40px;

}