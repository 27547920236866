/* Import the google web fonts you want to use */
@import url("https://fonts.googleapis.com/css?family=Comfortaa|Patua+One");

/*Typography
--------------------------------------------------------------*/
/* body,
button,
input,
select,
textarea {
  color: pink;
  font-family: "Comfortaa", Arial, sans-serif;
  font-size: 20px;
  line-height: 1.5;
}

body {
    padding: 0 3rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Patua One", serif;
  letter-spacing: 2px;
}

p {
  margin-bottom: 1.5em;
}

.title--main {
  margin-block-end: 0;
}
 */

 body {
  background-image: url(https://i.imgur.com/rkITftz.png);

 }