/* 
.forms {
    border: 4px;
    color: blue;
    align-items: right;
    min-width: 600px;
    min-height: 500px;
}

.contact__form {
    border: 330px;
    background-color: rgb(44, 78, 44);
    opacity: 50%;
    display: flex;
    flex-direction: right;
    align-items: center;
    max-height: 700px;
    min-width: 400px;
    min-height: 400px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 5px;
    background-color: #38373764;
    max-width: 700px;
    margin-left: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    
} */

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 330px;
    border-color: aquamarine;
    /* top: 80%; */
    /* background-color: rgb(44, 78, 44); */
    /* opacity: 50%; */
    /* max-width: 3%; */
    
  }